
import { defineComponent, onMounted, ref, watch } from 'vue'
import { message } from 'ant-design-vue'
import { getmappedAll, saveFaSetting, getBrandByBu, getAllBu,  } from '@/API/finance'
import { FaSettingTableProps} from './types'
import { getBrand, getSubscribedBu } from "@/API/approve";
import { BuItem, BrandItem, BrandResponse } from '@/views/Approve/SalesRoleApprove/ProgramHome/types';
import { useRouter, useRoute } from 'vue-router';
import useTableHeight from "@/hooks/useTableHeight";

const columns = [
    {
        title: 'Column Name',
        dataIndex: 'mappedName',
        key: 'mappedName',
        width: 200,
    },
    {
        dataIndex: 'columnSeq',
        key: 'columnSeq',
        slots: { customRender: 'sequence', title: 'Product Column Sequence' },
        width: 260,
    },
    {
        dataIndex: 'columnName',
        key: 'columnName',
        slots: { customRender: 'newcolumnName', title: 'Product Column Name' },
        width: 260,
    },
    {
        dataIndex: ' columnCnName',
        key: ' columnCnName',
        slots: { customRender: 'columnCnName', title: 'Product Column CN Name' },
        width: 260,
    },
    {
        dataIndex: 'rawDataShortList',
        key: 'rawDataShortList',
        slots: { customRender: 'shotList', title: 'Raw Data Short List' },
        width: 200,
    },
    {
        dataIndex: 'vehicleDisLevel',
        key: 'vehicleDisLevel',
        slots: { customRender: 'groupBase', title: 'Vehicle Dispaly Level' },
        width: 200,
    },
]
export default defineComponent({
    name: '',
    setup() {
        const { tableHeight } = useTableHeight(false);
        // 导航的数据
        const sheetArr = ref(['Financing', 'FL with Balloon', 'NEV Agility'])
        const router = useRouter();
        const route = useRoute()
        
        // table 的slots的数据
        const slotsCol = [
            {
                title: 'Product Column Sequence',
                key: 'columnSeq'
            },
            {
                title: 'Product Column Name',
                key: 'columnName'
            },
            {
                title: 'Raw Data Short List',
                key: 'rawDataShortList'
            },
            {
                title: 'Vehicle Dispaly Level',
                key: 'vehicleDisLevel'
            },
            {
                title: 'Product Column CN Name',
                key: 'columnCnName'
            }
        ]

        // 下拉品牌的数据
        const valueBrand = ref()
        const brandSelect = ref()
        
        // 监听brand等于EQ时
        watch(() => valueBrand.value, (newValue) => {
            if (newValue === 'EQ') {
                sheetArr.value = ['Financing']
            } else {
               sheetArr.value = ['Financing', 'FL with Balloon', 'NEV Agility']
            }
        } )
        // bu id
        const valueBu = ref()

        // 获取当前用户的bu id 集合
        const getBuidList = (buOptions: BuItem[]): string[] => {
            const arr: string[] = []
            buOptions.forEach(item => {
              arr.push(item.orgid)
            })
            return arr
        }

        // 通过bu 查找所有的brand
         const brandBybuList = ref()
     
        // sheet页
        const activeKey = ref()
        
        // 获取下拉选择内容
        const getSelectData = async() => {
            // 获取bu 
            brandBybuList.value = await getAllBu()

            valueBu.value = brandBybuList.value[0].orgid
            const params = {
              bu: valueBu.value,
              target: "Vehicle.brand",
            }
            const res: any = await getBrandByBu(params)
            brandSelect.value = res
            valueBrand.value = brandSelect.value[0] ? brandSelect.value[0] : ''
        }

        // table 数据
        const dataSource = ref<FaSettingTableProps[]>([])
        // 获取mapppeAll表格数据
        const getTableData = () => {
            const params = {
                bandId: valueBrand.value,
                buId: valueBu.value,
                sheetName: activeKey.value,
                programId: route.query.programId ? route.query.programId : 0,
                tableId: route.query.programId ? route.query.tableId : 0
            }
            getmappedAll({ params }).then(res => {
                dataSource.value = res
            })
        }

        const isDisabled = ref(false)
        // 初始化数据
        const init = async() => {
            await getSelectData()
            // 获取sheet页数据
            activeKey.value = sheetArr.value[0]
            if (route.query.tableId) {
                valueBu.value = route.query.bu
                valueBrand.value = route.query.brand
                activeKey.value = route.query.sheetName
                isDisabled.value = true
            }
            getTableData()
        }
  
        // 切换brand / bu 重新获取数据
        const handleChangeBu = (value: string) => {
            valueBrand.value = ''
            brandSelect.value = []
            // 根据bu 获取brand list
            const params = {
              bu: valueBu.value,
              target: "Vehicle.brand"
            }
            getBrandByBu(params).then((res: any) => {
              brandSelect.value = res
            })
        }

         const handleChangeBrand = () => {
            getTableData()
        }
        
        // 切换sheet 重新获取数据
        const cutTabs = () => {
            if (valueBu.value && valueBrand.value) {
                getTableData()
            }
            
        }

        // 清除当前列
        const refreshCol = (key: string) => {
            dataSource.value.forEach(item => {
                if (key === 'rawDataShortList' || key === 'vehicleDisLevel') {
                    item[key] = false
                } else {
                    item[key] = ''
                }
            }) 
        }

        // 查找数组中的最大值
        const findMaximum = (): number => {
            const arr: number[] = []
            dataSource.value.forEach((item) => {
                if (item.columnSeq) {
                    arr.push(+item.columnSeq)
                }
            })
            if (arr.length === 1) {
                return 0
            } else {
                const newArr = arr.sort((a, b) => b - a)
                return newArr[1] + 1
            }
        }

        // 去除重复的数据
        const deWeight = (value: string, valueIndex: number) => {
            dataSource.value.forEach((item, index) => {
                if (item.columnSeq == value && index !== valueIndex) {
                    item.columnSeq = ''
                }
            })
        }

        // 验证序号 是否是连续的， 第一个输入的是否是1
        const activeIndexs= ref<number[]>([])
        const errorMsg = ref()
        const verifySerial = (e: Event, index: number) => {
            const inpValue = e && (e.target as HTMLInputElement).value
            const value = +inpValue
            activeIndexs.value =Array.from(new Set(activeIndexs.value)) 
            if (!value) return
            // 判断正则的表达式
            const re = /^([1-9][0-9]{0,1}|100)$/
            if (!re.test(inpValue)) {
                activeIndexs.value.push(index)
                errorMsg.value = 'Please enter numbers from 1 to 100'
                return
            } 
            // 去除重复的数据
            deWeight(inpValue, index)
            // 判断一个输入的是否是 1
            if (!findMaximum()) {
                if (value !== 1) {
                    activeIndexs.value.push(index)
                    errorMsg.value = 'Please enter serial number 1'
                } else {
                    activeIndexs.value.splice(activeIndexs.value.indexOf(index), 1)
                }
            } else {
                // 判断是否按照顺序填写
                if (value > findMaximum()) {
                    activeIndexs.value.push(index)
                    errorMsg.value = 'Please enter a reasonable serial number'
                } else {
                    if (activeIndexs.value.indexOf(index) !== -1) activeIndexs.value.splice(activeIndexs.value.indexOf(index), 1)
                }
            }
        }

        // 点击保存
        const handleSave = () => {
            if (!valueBrand.value) {
                message.error('Please select a brand')
                return 
            }
            let arr: number[] = []
            // 保存已输入的序号
            dataSource.value.forEach(item => {
                if (item.columnSeq) {
                    arr.push(+item.columnSeq)
                }
            })
            arr = Array.from(new Set(arr))
            for (const index in arr) {
                const sum = +index + 1 
                // 不符合规则的时候
                if (!arr.includes((sum))) {
                    message.error('请检查输入的序号的格式')
                    return
                } 
            }
            // 存储的接口
            const porgramId: string|number = route.query.programId ? route.query.programId as string : 0
            const tableId = route.query.tableId ? route.query.tableId as string : 0
            
            saveFaSetting(valueBrand.value, valueBu.value, activeKey.value, porgramId, tableId, dataSource.value, ).then(() => {
                if (route.query.programId) {
                    router.push({
                        path: '/programdesign/design',
                        query: {
                            pgmId: route.query.programId
                        }
                    })
                }
                getTableData()
            })
        }

        onMounted(() => {
            init()
        })
         return {
             tableHeight,
             sheetArr,
             cutTabs,
             activeKey,
            
             brandBybuList,
             valueBrand,
             brandSelect,
             valueBu,
             handleChangeBu,
             handleChangeBrand,

             slotsCol,
             refreshCol,

             columns,
             dataSource,

             verifySerial,
             activeIndexs,
             errorMsg,

             handleSave,
             isDisabled,
             
         }

    }
 });
